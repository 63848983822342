<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="recordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="addOrEdit(null,'add')"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="claimAuditorManagement">
      <template slot="operate"
        slot-scope="{item}">
        <el-button v-if="handleHasPerms('M28-edit')"
          @click="addOrEdit(item,'edit')"
          class="polecy-button"
          type="text"
          size="small">编辑</el-button>
        <el-button v-if="handleHasPerms('M28-del')"
          @click="deleteItem([item])"
          type="text"
          size="small">删除</el-button>
      </template>
      <!-- <template slot="product"
        slot-scope="{item}">
        <span v-for="i,index in item.product"
          :key="index">{{i.dictValue}}</span>
      </template>
      <template slot="brand"
        slot-scope="{item}">
        <span v-for="i,index in item.brand"
          :key="index">{{i.dictValue}}</span>
      </template> -->
    </GeneralTable>
    <auditorAdd :show.sync="isAuditDialog"
      :inputData="inputData"
      :actionType="actionType"
      @confirm="addOrEditConfirm" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import auditorAdd from "@/views/claimAuditorManagement/components/auditorAdd.vue";
import { getAuditorList, addOrEditAuditor, deletAuditor } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { dictionaryBatch } from "@/api/policy";
export default {
  data() {
    return {
      option: {
        isAdd: hasPerms('M28-add'), //添加 事件 @add-change
        isDel: hasPerms('M28-del'),
        isEdit: hasPerms('M28-edit'), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isImport: false, // 导入 @import-click
        //isExport: hasPerms('M27-standard-export'),
        pageSize: 10,
        tableHeight: 'calc(100vh - 122px)', 
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [
          {
            label: '用户名',
            prop: 'account',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入用户名",
          }, {
            label: '审核角色',
            prop: 'auditRoleValue',
            formType: 'select',
            clearable: true,
            placeholder: "请选择审核角色",
            selectList: [
              { value: "", text: "全部" },
              { value: "区域经理", text: "区域经理" },
              { value: "理算专员", text: "理算专员" },
              { value: "其他", text: "其他" }]
          }, {
            label: '负责部门',
            prop: 'deptName',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入部门",
          },
          {
            label: '负责产品线',
            prop: 'productValue',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入产品线",
          },
          {
            label: '负责品牌',
            prop: 'brandValue',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入品牌",
          },
          {
            label: '创建时间',
            prop: 'createTime',
            formType: 'daterange',
            maxLength: 32,
            clearable: true,
            format: 'yyyy-MM-dd',
            prop: ['createTimeBegin', 'createTimeEnd'],
          }, {
            label: '更新时间',
            prop: 'updateTime',
            formType: 'daterange',
            maxLength: 32,
            clearable: true,
            format: 'yyyy-MM-dd',
            prop: ['updateTimeBegin', 'updateTimeEnd'],
          }
        ]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "用户名",
          prop: "account",
          align: 'center',
          width: 100,
          isShow: true,
        },
        {
          label: "审核角色",
          prop: "auditRoleValue",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "负责部门",
          prop: "deptName",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "负责产品线",
          prop: "productValue",
          align: 'center',
          width: 200,
          isShow: true,
          //  isSlot: true,
        },
        {
          label: "负责品牌",
          prop: "brandValue",
          align: 'center',
          width: 200,
          isShow: true,
          //  isSlot: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 200,
          fixed: 'right',
          isSlot: true,
        },
      ],
      searchData: {
      },
      oldSearchData: {
      },
      //数据总量
      totalNum: 0,
      recordList: [],
      approvalType: 1,
      isAuditDialog: false,
      AuditId: '',
      inputData: null,
      actionType: 'add'
    };
  },
  components: { GeneralTable, auditorAdd },
  created() {
  },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      let currentData = {};
      if (isReset == 'reset') {
      } else if (isReset == 'refresh') {
        currentData = JSON.parse(JSON.stringify(this.oldSearchData));
      } else {
        this.oldSearchData = JSON.parse(JSON.stringify(this.searchData));
        currentData = JSON.parse(JSON.stringify(this.searchData));
      }

      if (v.startTimeBegin) {
        v.startTimeBegin += ' 00:00:00';
        v.startTimeEnd += ' 23:59:59';
      }

      const { code, data } = await getAuditorList({ ...v, ...currentData, current: pageNum, size: pageSize, vague: v.keyword, processType: this.approvalType });
      if (code === 200 && data.records && data.records.length) {
        this.recordList = data.records;
        this.totalNum = data.total;
      } else {
        this.totalNum = 0;
        this.recordList = [];
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm('确认删除该数据?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id);
          deletAuditor({ ids: ids.toString() }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的数据！');
      }
    },
    exportData(e) {
      if (!e || !e.length) {
        this.$message.warning('请选择需要导出的数据！');
        return false;
      }
      insClaimsProcessExportByIds(e.map(val => val.id));
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDataPermissions(key, perms) {
      return dataPermissions(key, perms);
    },
    addOrEdit(data, type) {
      this.actionType = type;
      this.inputData = data;
      this.isAuditDialog = true;
    },
    addOrEditConfirm(data) {
      addOrEditAuditor(data).then(res => {
        this.$refs.GeneralTable.getList();
        this.$message.success('成功！');
      });
    },
    deletAuditor() {
      deletAuditor();
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
    .status {
      position: relative;
      padding-left: 18px;
      font-size: 14px;
    }
    .status::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .status1 {
      color: #00bc0d;
    }
    .status1::after {
      background-color: #00bc0d;
    }
    .status2 {
      color: #0080ff;
    }
    .status2::after {
      background-color: #0080ff;
    }
    .status3 {
      color: #cccccc;
    }
    .status3::after {
      background-color: #cccccc;
    }
    .status4 {
      color: #c94242;
    }
    .status4::after {
      background-color: #c94242;
    }
  }
}
.reset-password {
  display: flex;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 12px;
}
::v-deep .el-dropdown,
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>