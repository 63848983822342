<template>
  <el-popover :disabled="disabled"
    :placement="placement"
    width="500"
    ref="popoverbox"
    trigger="click"
    @show="handleChange">
    <div class="mian-container">
      <div class="transfer">
        <div class="transfer-left">
          <!-- 员工搜索 -->
          <div>
            <el-input placeholder="请输入部门名称"
              prefix-icon="el-icon-search"
              v-model="filterText" />
          </div>

          <!-- 树形结构 -->
          <div v-if="isTree">
            <el-tree show-checkbox
              :data="treeData"
              default-expand-all
              node-key="id"
              :props="defaultProps"
              ref="structureTree"
              @check="selectNode"
              :filter-node-method="filterNode">
              <span slot-scope="{ node, data }">
                <span>
                  {{ node.label }}
                  <el-tag v-if="
                      data[defaultProps.typeName] ==
                      defaultProps[defaultProps.typeName]
                    ">用户</el-tag>
                  <el-tag v-else
                    type="success">部门</el-tag>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="mian-container el-select"
      style="display: block; height: 32px"
      slot="reference">
      <div :class="{ 'el-input__inner': true, 'disabled-s': disabled }"
        style="height: 32px">
        <div style="
            white-space: nowrap;
            overflow: hidden;
            height: 32px;
            line-height: 32px;
          ">
          {{ selectName }}
        </div>
      </div>
    </div>
    <div class="bottom-btn"
      v-if="isMulti">
      <el-button @click="closePopover"
        type="primary">确定</el-button>
    </div>
  </el-popover>
</template>

<script>
import { getRelateTreeData, getOrganizationTree } from "@/api/SystemData";
export default {
  name: "DeptSelectTree",
  props: {
    value: [String, Array, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "bottom"
    }
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value: {
      handler(v) {
        if (this.treeData && this.treeData.length) {
          if (!this.isMulti) {
            this.selectName = "";
          }
          this.$nextTick(() => {
            this.handleInitEcho(this.treeData);
          });
        }
        if (!v) {
          this.selectName = "";
        }
        // if (v instanceof Array) {
        //   this.$nextTick(() => {
        //     this.$refs.structureTree.setCheckedKeys(v);
        //   });
        // } else {
        //   this.$nextTick(() => {
        //     this.$refs.structureTree.setCheckedKeys([v]);
        //   });
        // }
      },
    },
    filterText(value) {
      this.$refs.structureTree.filter(value);
    },
  },
  data() {
    return {
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      selectName: "",
      isTree: true,
    };
  },
  created() {
    this.getTreeData();
  },
  methods: {
    async getTreeData() {
      let treeData = await getOrganizationTree({
        tenantId: this.$store.state.tenantId,
      });
      if (treeData.data) {
        this.treeData = treeData.data;
        if (this.value) {
          this.$nextTick(() => {
            this.handleInitEcho(this.treeData);
          });
        }
      }
    },
    handleInitEcho(e) {
      e.map((val) => {
        if (this.isMulti && this.value) {
          if (
            this.value.includes(val.id) &&
            !this.selectName.includes(val.title)
          ) {
            if (
              this.selectName.split(",").length === this.value.split(",").length
            ) {
              this.selectName += val.title;
            } else {
              this.selectName += val.title + ",";
            }
          }
        } else {
          if (val.id == this.value) {
            this.selectName = val.title;
          }
        }
        if (!this.selectName && val.children && val.children.length) {
          this.handleInitEcho(val.children);
        }
      });
      if (this.isMulti) {
        this.selectName.slice(0, this.selectName.length - 1);
      }
    },
    selectNode(data, { checkedNodes }) {
      let keys = this.$refs.structureTree.getCheckedKeys();
      let nodeIds = [];
      checkedNodes.forEach((node) => {
        nodeIds.push(node.id);
      });
      let ids = Array.from(new Set(nodeIds));
      if (keys && keys.length) {
        let names = [];
        if (this.isMulti) {
          this.$refs.structureTree.setCheckedKeys(ids);
          checkedNodes.forEach((item) => {
            names.push(item.title);
          });
          var newNames = names.filter((item, index) => {
            return names.indexOf(item) === index;
          });
          this.selectName = newNames.toString();
        } else {
          //this.$refs.structureTree.setCheckedKeys([data.id]);
          this.selectName = data.title;
        }
        if (this.isMulti) {
          this.$emit("change", ids.toString(), names.toString());
        } else {
          this.$emit("change", data.id, data.title);
        }
        this.$emit("changeChecked", data);
        if (!this.isMulti) {
          this.$refs.popoverbox.doClose();
        }
      } else {
        this.selectName = "";
      }
    },
    handleChange(e) {
      this.filterText = '';
      this.isTree = false;
      if (this.value) {
        this.$nextTick(() => {
          this.isTree = true;
          if (this.value instanceof Array) {
            this.$nextTick(() => {
              this.$refs.structureTree.setCheckedKeys(this.value);
            });
          } else {
            this.$nextTick(() => {
              this.$refs.structureTree.setCheckedKeys(this.value?.split(","));
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.isTree = true;
        });
        this.$refs.structureTree.setCheckedKeys([]);
      }
    },
    closePopover() {
      this.$refs.structureTree.setCheckedKeys([]);
      this.$refs.popoverbox.doClose();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
.title {
  display: flex;
  justify-content: left;
  color: #ffffff;
}
.mian-container {
  display: column;
  max-height: 400px;
  overflow: auto;
  .header {
    .header-search {
      width: 100%;
    }
  }
  .transfer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .middle-icon {
      color: #4278c9ff;
      align-self: center;
      font-size: 20px;
      transform: rotate(90deg);
    }
    > div {
      padding: 10px;
      margin: 0 5px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #cccccc;
    }
    .transfer-left {
      flex: 1;
      .left-top {
        margin-bottom: 5px;
      }
    }
    .transfer-right {
      display: column;
      flex: 1;
      .right-top {
        .clear {
          color: #4278c9ff;
        }
      }
      .select-list {
        display: column;
        margin: 5px 0;
        .select-item {
          display: flex;
          padding: 5px;
          justify-content: space-between;
          align-items: center;
          .el-icon-circle-close {
            display: none;
          }
        }
        .select-item:hover {
          background: #bbcfee;
          border-radius: 4px;
          .el-icon-circle-close {
            display: inline;
          }
        }
      }
    }
  }
  .transfer-top {
    display: flex;
    justify-content: space-between;
    background-color: #ffffffff;
    padding: 5px;
    border-radius: 5px;
  }
  .text-top {
    > span {
      margin: 0 5px;
    }
  }
}
.el-select {
  overflow: hidden;
}
.disabled-s {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
.bottom-btn {
  margin-right: 10px;
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}
</style>
