<template>
  <el-dialog
    :visible.sync="visible"
    @close="clearClose"
    @open="fillData"
    destroy-on-close
    :close-on-click-modal="false"
    width="700px"
  >
    <div class="title" slot="title">
      {{ titleName }}
    </div>
    <div slot="footer">
      <el-button @click="confirm">确定</el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <el-form :model="form" :rules="rules" ref="form" label-position="right">
      <el-form-item label="用户名:" prop="userId">
        <EmployeeSelect ref="userId" v-model="form.userId" />
      </el-form-item>
      <el-form-item label="审核角色:" prop="auditRoleKey">
        <el-select
          v-model="form.auditRoleKey"
          ref="auditRoleKey"
          placeholder="请选择"
        >
          <el-option
            v-for="item in claimApproveRole"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责部门:" prop="deptId">
        <DeptSelectTree ref="deptId" v-model="form.deptId" />
      </el-form-item>
      <el-form-item label="负责产品线:" prop="productKey">
        <el-select
          multiple
          value-key="dictKey"
          v-model="form.product"
          ref="productKey"
          placeholder="请选择"
        >
          <el-option
            v-for="item in vehicleType"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责品牌：" prop="brandKey">
        <el-select
          multiple
          value-key="dictKey"
          v-model="form.brand"
          ref="brandKey"
          placeholder="请选择"
        >
          <el-option
            v-for="item in vehicleBrand"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { dictionaryBatch } from "@/api/policy";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import DeptSelectTree from "@/components/DeptSelectTree/index.vue";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "auditorAdd",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: "add",
    },
    actionTypeEmun: {
      type: Object,
      default: () => {
        return {
          add: "添加",
          edit: "编辑",
        };
      },
    },
    inputData: {
      type: Object,
      default: () => null,
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "label",
          disabled: "disabled",
        };
      },
    },
    defaultNode: {
      type: Object,
      default: () => {
        return {
          id: null,
          label: null,
        };
      },
    },
  },
  components: { EmployeeSelect, DeptSelectTree },
  data() {
    return {
      visible: false,
      claimApproveRole: [],
      vehicleType: [],
      vehicleBrand: [],
      form: {
        id: "",
        account: " ",
        userId: "",
        auditRoleKey: "",
        auditRoleValue: "",
        deptId: "",
        product: [],
        productKey: "",
        productValue: "",
        brand: [],
        brandKey: "",
        brandValue: "",
      },
      oldForm: {},
      rules: {
        userId: [{ required: true, message: "请选择用户", trigger: ["blur"] }],
        auditRoleKey: [
          { required: true, message: "请选择审核角色", trigger: ["blur"] },
        ],
        deptId: [
          { required: true, message: "请选择负责部门", trigger: ["blur"] },
        ],
      },
    };
  },
  watch: {
    visible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      if (n) {
        dictionaryBatch({
          codes: "vehicleType,vehicleBrand,claimApproveRole",
        }).then((res) => {
          let { vehicleType, vehicleBrand, claimApproveRole } = res.data;
          this.vehicleType = vehicleType;
          this.vehicleBrand = vehicleBrand;
          this.claimApproveRole = claimApproveRole;
        });
      }
      if (this.actionType == "edit") {
        this.form = _.cloneDeep(this.inputData);
        this.$set(this.form, "product", []);
        this.$set(this.form, "brand", []);
        let productValueList = this.inputData.productValue.split(",");
        let brandValueList = this.inputData.brandValue.split(",");
        this.form.product = this.inputData.productKey
          .split(",")
          .map((item, index) => {
            return {
              dictKey: Number(item),
              dictValue: productValueList[index],
            };
          });
        this.form.brand = this.inputData.brandKey
          .split(",")
          .map((item, index) => {
            return { dictKey: Number(item), dictValue: brandValueList[index] };
          });
        this.oldForm = _.cloneDeep(this.form);
      } else {
        this.clearClose();
      }
      this.visible = n;
    },
  },

  mounted() {},
  computed: {
    titleName() {
      return this.actionTypeEmun[this.actionType];
    },
  },
  methods: {
    reset() {
      this.form = _.cloneDeep(this.oldForm);
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let emitData = _.cloneDeep(this.form);
          //  if (this.actionType == "edit") {
          //    emitData = {
          //      ...this.form,
          //      id: this.inputData.id,
          //    };
          //  }
          let productValueList = [];
          let brandValueList = [];
          let productKeyList = [];
          let brandKeyList = [];
          this.form.product.map((item) => {
            productValueList.push(item.dictValue);
            productKeyList.push(item.dictKey);
          });
          this.form.brand.map((item) => {
            brandValueList.push(item.dictValue);
            brandKeyList.push(item.dictKey);
          });
          let nameData = {
            account: this.$refs.userId.selectName,
            deptName: this.$refs.deptId.selectName,
            auditRoleValue: this.$refs.auditRoleKey.selected.label,
            productValue: productValueList.toString(),
            brandValue: brandValueList.toString(),
            productKey: productKeyList.toString(),
            brandKey: brandKeyList.toString(),
          };

          this.$emit("confirm", Object.assign(emitData, nameData));
          this.visible = false;
        }
      });
    },
    clearClose() {
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] instanceof Array) {
          this.form[key] = [];
        } else {
          this.form[key] = "";
        }
      });
      this.oldForm = _.cloneDeep(this.form);
    },
    fillData() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-select {
  width: 100%;
}
.title {
  color: #ffffff;
}
</style>
